body {
  margin: 0;
  font-weight: 400;
  font-family: "Inter", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: none !important;
}

html {
  min-height: 100%;
  background-color: #f0f0f0;
}

strong,
b,
strong *,
b * {
  font-weight: 600;
}
em,
i,
em *,
i * {
  font-style: italic;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/**
 * Remove chevron from input[type="number"]
 */
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/**
 * Firefox
 */
input[type="number"] {
  -moz-appearance: textfield;
}
